<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15 row">
            <div class="col-sm-7">
                <div class="pd-t-5 pd-b-5">
                    <h1 class="pd-0 mg-0 tx-20 text-overflow">Transaksi Masuk</h1>
                </div>
                <div class="breadcrumb pd-0 mg-0">
                    <a class="breadcrumb-item" href="#"><i class="fa fa-clipboard mg-r-5"></i>Transaksi Masuk</a>
                    <span class="breadcrumb-item active"> Pengembalian</span>
                </div>
            </div>
            <div v-if="userdata.role == 'auditor' || userdata.role == 'administrator'" class="col-sm-5">
                Filter SKPD:
                <v-select 
                        :options="listPengguna"
                        :reduce="(label) => label.code"
                        label="label"
                        v-model="filter"
                        @input="handleFilter"
                        ></v-select>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div v-if="userInfo != 4 && userdata.email == '2025'" class="alert alert-danger text-center" role="alert">
            Silahkan periksa dan validasi Saldo Awal anda terlebih dahulu untuk melanjutkan transaksi!
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari No Bukti/No Dokumen/Tgl Dokumen/Tgl Buku...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button v-if="(userdata.email == '2024' && userdata.parent != 0) || (userdata.email == '2025' && userInfo == 4)" class="btn btn-primary ml-2">
                                        <div class=" d-flex align-items-center">
                                            <router-link :to="{ name:'transaksi_masuk.form_pengembalian',params: { id: 0 }}">
                                            <i class="text-white fe fe-plus">&nbsp;Tambah Transaksi</i>
                                            </router-link>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>No Dokumen</th>
                                            <th>No Bukti</th>
                                            <th>Tgl Dokumen</th>
                                            <th>Tgl Buku</th>
                                            <th>Rupiah</th>
                                            <th>SKPD</th>
                                            <th class="text-center" style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataTransaksi.length == 0">
                                        <tr>
                                            <td colspan="8">
                                                <div class="alert alert-danger text-center" role="alert">
                                                    Belum Ada Data
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in dataTransaksi" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.no_dokumen }}</td>
                                            <td>{{ item.no_bukti }}
                                            <a v-if="item.lampiran" :href="$apiconfig + 'uploads/' + item.lampiran" target="_blank" style="cursor:pointer" class="text-primary"><i class="fa fa-external-link ml-2"></i></a></td>
                                            <td>{{ item.tgl_dokumen }}</td>
                                            <td>{{ item.tgl_buku }}</td>
                                            <td >Rp. {{ item.rupiah | VMask(currencyMask) }}</td>
                                            <td>{{ item.nickname }}</td>
                                            <td class="text-center">
                                                <router-link target="_blank" :to="{ name:'transaksi_masuk.form_pengembalian',params: { id: item.id }}">
                                                <button class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                </router-link>
                                                <button v-if="userdata.parent != 0" @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                currencyMask,
                dataTransaksi: [],
                listPengguna: [],
                fetching: true,
                status: 'pengembalian',
                form: {
                    id: '',
                    code: '',
                    satuan: '',
                    name: '',
                },
                filter: '',
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        computed: mapState(['userdata']),
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
            this.getUser();
            this.checkUser();
        },
        components: {
            vPagination,
            'v-select' : vSelect,
        },
        methods: {
            checkUser() {
                if(this.userdata.role == 'dinas'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/info', {
                    params: {
                            parent: this.userdata.id,
                        }
                    })
                        .then((response) => {
                            this.userInfo = response.data.data[0].parent;
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'transaksi/masuk', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        status: this.status,
                        filter: this.filter,
                    }
                })
                    .then((response) => {
                        this.dataTransaksi = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getUser() {
                this.fetching = true;
                if(this.userdata.role == 'auditor' || this.userdata.role == 'administrator'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/list', {
                    params: {
                            keywords: this.keywords,
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            handleFilter: function() {
                this.getData();
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'transaksi/masuk/remove',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if(response.data.status){
                                this.$swal(
                                    'Deleted!',
                                    response.data.message,
                                    'success'
                                )
                                this.getData();
                            }else{
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>