<template>
    <!-- CONTENT ================================================== -->
    <div id="login_page"  class="container-fluid">
        <div class="row justify-content-center py-5">
            <div class="col-12 col-md-10 col-xl-7 py-5">
                <div class="card shadow-sm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="container">
                                <div class="wrapper">
                                    <!-- <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/0bda02a02c667e33b0198563/desktop%20computer-bro-min.png"> -->
                                    <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/0bda02a02c667e33b0198563/Shared%20goals-bro-min.png">
                                    <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/0bda02a02c667e33b0198563/Security-bro-min.png">
                                    <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/0bda02a02c667e33b0198563/Insurance-bro-min.png">
                                    <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/5fc7eaf8a446cec187ea77d8/Achievement-bro-min.png">
                                </div>
                            </div>
                            <!-- <div class="card-col-img" style="height:100%;background-repeat: no-repeat;background-size: contain" v-bind:style="{ 'background-image': 'url(https://scola-uploader.sgp1.digitaloceanspaces.com/upload/8f7f386637ccf197739b8f6b/banner.jpg)' }"></div> -->
                        </div>
                        <div class="col-md-6">
                            <div class="card-body py-2">
                                <!-- Heading -->
                                <h1 class="display-4 text-center mb-3">
                                    <img style="width:20%" src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png">
                                </h1>
                                <!-- Subheading -->
                                <h5 class="text-muted text-center mb-3">
                                    Sistem Informasi Persediaan
                                    <h6 v-if="checkDomain != 'persediaan.e-planningbmd.id'">( Tahun 2024 )</h6>
                                    <!-- HIDE SEMESTARA -->
                                    <h6 v-if="checkDomain == 'persediaan.e-planningbmd.id'">( Tahun 2025 )</h6>
                                    <!-- HIDE SEMESTARA -->
                                </h5>
                                <!-- Form -->
                                <form v-on:submit.prevent="handleSubmit">
                                    <!-- Email address -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label>Username</label>
                                        <!-- Input -->
                                        <input type="username" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.username.$error }"
                                            v-model="username" placeholder="Masukan username">
                                        <div v-if="submitted && !$v.username.required" class="invalid-feedback">Username
                                            is required</div>
                                    </div>
                                    <!-- Password -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col">

                                                <!-- Label -->
                                                <label>Password</label>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Help text -->
                                                <!-- <a href="#" class="form-text small text-muted">
													Lupa kata sandi?
												</a> -->
                                            </div>
                                        </div> <!-- / .row -->
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <!-- input-group-merge -->
                                            <!-- Input -->
                                            <!-- Input -->
                                            <input type="password" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                v-model="password" placeholder="Masukan password">
                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                                Password is required</div>
                                            <!-- Icon -->
                                            <!-- <div class="input-group-append">
												<span class="input-group-text">
													<i class="fe fe-eye"></i>
												</span>
											</div> -->
                                        </div>
                                    </div>
                                    <!-- Submit -->
                                    <!-- Submit -->
                                    <button type="submit" class="btn btn-lg btn-block btn-success mb-3">
                                        Login
                                    </button>

                                    <!-- <div v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="alert alert-danger alert-bordered pd-y-15" role="alert">
                                        <div class="d-sm-flex align-items-center justify-content-start">
                                            <div class="mg-t-20 mg-sm-t-0">
                                                <p class="text-dark mg-b-0 tx-gray"> Silahkan Klik dan Login <a href="https://persediaanbmd.bandungkab.go.id/" class="text-info"><b>disini (Persediaan 2024)</b></a> untuk input transaksi 2024, dengan catatan BA Stockopname sudah divalidasi</p>
                                            </div>
                                        </div>
                                    </div> -->
   
                                    <div v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="alert alert-warning alert-bordered pd-y-15" role="alert">
                                        <div class="d-sm-flex align-items-center justify-content-start">
                                            <div class="mg-t-20 mg-sm-t-0 text-center">
                                                <p class="text-dark text-center mg-b-0 tx-gray">Masuk ke Transaksi <a href="https://persediaan.e-planningbmd.id/" class="text-center text-info"><b>Transaksi 2025</b></a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- HIDE SEMENTARA -->
                                    <div v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="alert alert-info alert-bordered pd-y-15" role="alert">
                                        <div class="d-sm-flex align-items-center justify-content-start">
                                            <div class="mg-t-20 mg-sm-t-0 text-center">
                                                <p class="text-dark text-center mg-b-0 tx-gray">Kembali ke <a href="https://persediaanbmd.bandungkab.go.id/" class="text-center text-info"><b>Transaksi 2024</b></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- HIDE SEMENTARA -->


                                    <!-- Link -->
                                    <!-- <div class="text-center">
									<small class="text-muted text-center">
										Don't have an account yet? <a href="sign-up.html">Sign up</a>.
									</small>
									</div> -->
                                </form>
                                <!-- <div v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="alert alert-warning alert-bordered pd-y-15" role="alert">
                                    <div class="d-sm-flex align-items-center justify-content-start">
                                        <div class="mg-t-20 mg-sm-t-0 text-center">
                                            <p class="text-dark text-center mg-b-0 tx-gray">⚠️ Saat ini Transaksi 2023 sudah ditutup, silahkan kembali ke <a href="https://persediaanbmd.bandungkab.go.id/" class="text-center text-info"><b>Transaksi 2024</b></a></p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</template>

<style>
    #login_page {
        background-image: url(https://scola-uploader.sgp1.digitaloceanspaces.com/upload/c0cd5ac94ce4baa0aadca684/%E2%80%94Pngtree%E2%80%94green%20gradient%20white%20simple%20circle_1454597.png);
        background-size: 100% 100%;
        background-attachment: fixed;
        animation: slideBackground 30s linear infinite;
        height: 100vh;
    }

    @keyframes slideBackground {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: 100% 0%;
        }
    }

    .container{
    width: 80%;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 58%;
    overflow: hidden;
    border: 10px solid #ffffff;
    border-radius: 8px;
    box-shadow: 10px 25px 30px #ffffff;

    }
    .wrapper{
        width: 100%;
        display: flex;
        animation: slide 16s infinite;
    }
    @keyframes slide{
        0%{
            transform: translateX(0);
        }
        25%{
            transform: translateX(0);
        }
        30%{
            transform: translateX(-100%);
        }
        50%{
            transform: translateX(-100%);
        }
        55%{
            transform: translateX(-200%);
        }
        75%{
            transform: translateX(-200%);
        }
        80%{
            transform: translateX(-300%);
        }
        100%{
            transform: translateX(-300%);
        }
    }
    img{
        width: 100%;
    }
</style>


<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Website Persediaan BMD Kabupaten Bandung',
            meta: [
            {
                name: 'description',
                content: 'Website Persediaan BMD Kabupaten Bandung'
            },
            {
                name: 'keywords',
                content: 'Persediaan, Kab Bandung, Kabupaten Bandung, Persediaan Kabupaten Bandung, Persediaan kab Bandung, Web Persediaan, Persediaan BMD'
            }
            ]
        },
        created() {
            const head = document.head;

            const metaDescription = document.createElement('meta');
            metaDescription.name = 'description';
            metaDescription.content = 'Website Persediaan BMD Kabupaten Bandung';

            const metaKeywords = document.createElement('meta');
            metaKeywords.name = 'keywords';
            metaKeywords.content = 'Persediaan, Kab Bandung, Kabupaten Bandung, Persediaan Kabupaten Bandung, Persediaan kab Bandung, Web Persediaan, Persediaan BMD';

            head.appendChild(metaDescription);
            head.appendChild(metaKeywords);

            this.check_domain();
        },
        data() {
            return {
                username: "",
                password: "",
                submitted: false,
                checkDomain: '',
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            check_domain() {
                this.checkDomain = window.location.hostname;
                // this.checkDomain = 'persediaan.e-planningbmd.id';
                // this.checkDomain = 'persediaanbmd.bandungkab.go.id';
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                this.$router.push('/admin/dashboard');
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
        }
    }
</script>